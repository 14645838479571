<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";

/**
 * Tabs & accordions component
 */
export default {
  page: {
    title: "订单详情",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "订单详情",
      items: [
        {
          text: "GreenPont",
          href: "/"
        },
        {
          text: "UI Elements",
          href: "/"
        },
        {
          text: "Tabs & accordions",
          active: true
        }
      ],
      text: `
         Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.
        `,
      content: `Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid. Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit.`
    };
  }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">订单详情</h4>
            <p class="card-title-desc">
              深圳绿朴可持续发展
            </p>
            <b-tabs content-class="p-3">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">客户信息</span>
                </template>
                <p class="mb-0">{{ text }}</p>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">服务信息</span>
                </template>
                <p class="mb-0">{{ content }}</p>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">服务进度</span>
                </template>
                <p class="mb-0">{{ text }}</p>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">支付开票</span>
                </template>
                <p class="mb-0">{{ content }}</p>
              </b-tab>
            </b-tabs>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>